//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    isMobile: Boolean,
    error: {
      type: Object,
      default: () => {}
    },
    isBookFailed: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      this.$emit('on-click')
    }
  }
}
