//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Illustration from '@/components/svg/Illustration'

export default {
  components: {
    Illustration
  },
  props: {
    isMobile: Boolean,
    errorBookResponse: {
      type: Object,
      default: () => {}
    }
  }
}
