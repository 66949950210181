//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const SIZE = {
  small: 15,
  medium: 30,
  large: 45
}

export default {
  props: {
    type: {
      type: String,
      default: 'bounce',
      validator: value => ['bounce', 'circle'].indexOf(value) !== -1
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => Object.keys(SIZE).indexOf(value) !== -1
    },
    isLight: Boolean
  },
  computed: {
    bounceParent() {
      return SIZE[this.size] * 3 + 'px'
    },

    bounceChild() {
      return SIZE[this.size] / 2 + 'px'
    },

    circleParent() {
      return SIZE[this.size] + 'px'
    }
  }
}
