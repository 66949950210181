export default $axios => ({
  // -------------------------------------------------------------------------
  // AUTO COMPLETE
  // -------------------------------------------------------------------------

  autocompleteFlight(params, options = {}) {
    return $axios.$get(
      '/fl/autocomplete/search',
      { params },
      {
        progress: false,
        ...options
      }
    )
  },

  getFlightList(lang) {
    return $axios.$get(`/fl/autocomplete/list?lang=${lang}`, {
      progress: false
    })
  },

  getFlightSuggestion(lang, country, depAirport) {
    if (!!depAirport)
      return $axios.$get(
        `/fl/autocomplete/suggestion?lang=${lang}&country=${country.toUpperCase()}&depAirport=${depAirport}`,
        { progress: false }
      )
    return $axios.$get(
      `fl/autocomplete/suggestion?lang=${lang}&country=${country.toUpperCase()}`,
      { progress: false }
    )
  },

  // -------------------------------------------------------------------------
  // BOOK
  // -------------------------------------------------------------------------

  flightSubmit(payload) {
    return $axios.$post('/fl/book/flight-submit', payload)
  },

  flightFormConfig(payload) {
    return $axios.$post('/fl/book/form-config', payload)
  },

  flightBookVerify(payload, options = {}) {
    return $axios.$post('/fl/book/verify', payload, {
      progress: false,
      ...options
    })
  },

  flightOrder(payload) {
    return $axios.$post('/fl/book/order', payload)
  },

  // -------------------------------------------------------------------------
  // CONTENT
  // -------------------------------------------------------------------------

  getPopularRoutes(country) {
    return $axios.$get(`/fl/content/popular-routes?country=${country}`)
  },

  // -------------------------------------------------------------------------
  // FLIGHT
  // -------------------------------------------------------------------------
  flightVerify(payload, options = {}) {
    return $axios.$post('/fl/flight/verify', payload, {
      progress: false,
      ...options
    })
  },

  flightSearch(payload, options = {}) {
    return $axios.$post('/fl/flight/search', payload, {
      progress: false,
      ...options
    })
  },

  liveCrawlFlight(payload, options = {}) {
    return $axios.$post('/fl/flight/livecrawl', payload, {
      progress: false,
      ...options
    })
  },

  liveCrawlReturnFlight(payload, options = {}) {
    return $axios.$post('/fl/flight/livecrawl-roundtrip', payload, {
      progress: false,
      ...options
    })
  },

  liveCrawlUpdateFlight(payload) {
    return $axios.$post('/fl/flight/livecrawl-update', payload)
  },

  liveCrawlMonitor(payload, options = {}) {
    return $axios.$post('/fl/flight/livecrawl-monitor', payload, {
      progress: false,
      ...options
    })
  },

  getFareCalendar(payload) {
    return $axios.$get(
      '/fl/flight/fare-calendar',
      { params: payload },
      {
        progress: false
      }
    )
  },

  getFlightSchedule(payload) {
    return $axios.$get('/fl/flight/schedule', { params: payload })
  },

  getCheapestFlight(payload) {
    return $axios.$get('/fl/flight/cheapest-flight', { params: payload })
  },

  // -------------------------------------------------------------------------
  // PRICE ALERT
  // -------------------------------------------------------------------------

  getPriceAlert() {
    return $axios.$post('/fl/price-alert/list')
  },

  addPriceAlert(payload) {
    return $axios.$post('/fl/price-alert/add', payload)
  },

  deletePriceAlert(id) {
    return $axios.$delete(`/fl/price-alert/delete?id=${id}`)
  },

  // -------------------------------------------------------------------------
  // PROMO
  // -------------------------------------------------------------------------

  getPromoHomepageFlight(country, currency) {
    return $axios.$get(
      `/fl/promo/homepage-list?country=${country}&currency=${currency}`,
      { useCache: true }
    )
  },

  getPromoListFlight(payload) {
    return $axios.$get('/fl/promo/list', { params: payload })
  },

  getPromoPageList() {
    return $axios.$get('/fl/promo/page')
  },

  // -------------------------------------------------------------------------
  // ROUTES
  // --------------------------------------------------------------------------

  getAllFlight(payload) {
    return $axios.$get('/fl/flight/to', {
      params: payload
    })
  }
})
