export default {
  defaultRoom: 1,
  defaultAdult: 1,
  defaultChild: [],
  defaultSort: 'recommendation',
  defaultPage: 1,
  defaultSortItems: Object.freeze([
    {
      id: 'recommendation',
      text: 'hotel.recommended'
    },
    {
      id: 'highest_price',
      text: 'global.highestprice'
    },
    {
      id: 'lowest_price',
      text: 'global.lowestprice'
    }
    // {
    //   id: 'review_score',
    //   value: false,
    //   text: 'Review Score'
    // }
  ]),
  defaultSpecialRequestChecks: Object.freeze([
    {
      id: 'nonsmoking',
      text: 'bookingform.nonsmokingroom'
    },
    {
      id: 'smoking',
      text: 'bookingform.smokingroom'
    },
    {
      id: 'locker',
      text: 'bookingform.safetylocker'
    },
    {
      id: 'latecheck',
      text: 'bookingform.latecheckin'
    },
    {
      id: 'highfloor',
      text: 'bookingform.highfloor'
    },
    {
      id: 'lowfloor',
      text: 'bookingform.lowfloor'
    },
    {
      id: 'wheelchair',
      text: 'bookingform.wheelchairacc'
    },
    {
      id: 'largebed',
      text: 'bookingform.largebed'
    },
    {
      id: 'twinbed',
      text: 'bookingform.twinbed'
    }
  ]),
  defaultResultQuickFilters: Object.freeze({
    type: 'all',
    value: 0
  }),
  defaultResultFilters: Object.freeze({
    price: [0, 0],
    stars: [],
    facilities: [],
    types: []
  })
}
