export default class CustomFormatter {
  constructor() {}

  interpolate(message, values) {
    const result = []
    if (!!values) {
      if (!Array.isArray(values)) {
        if (message.includes('%s'))
          Object.entries(values).forEach(element => {
            const regex = new RegExp(`%${element[0]}`, 'g')
            message = message.replace(regex, element[1])
          })
        else
          Object.entries(values).forEach(element => {
            const regex = new RegExp(`{${element[0]}}`, 'g')
            message = message.replace(regex, element[1])
          })
      } else {
        if (message.includes('%s'))
          values.forEach(content => {
            message = message.replace(`%s`, content)
          })
        else
          values.forEach((content, idx) => {
            message = message.replace(`[${idx}]`, content)
          })
      }
    }
    if (!message) message = 'No Translation exists'
    message = message.replace('&amp;', '&')
    result.push(message)
    return [result]
  }
}
