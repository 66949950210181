//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ApzModal from '@/components/base/ApzModal'

export default {
  name: 'ApzPopover',
  extends: ApzModal,
  props: {
    title: {
      type: String,
      default: ''
    },
    contentStyle: {
      type: String,
      default: ''
    },
    programmatic: {
      type: Boolean,
      default: true
    }
  }
}
