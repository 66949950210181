export default $axios => ({
  // -------------------------------------------------------------------------
  // AIRLINE
  // -------------------------------------------------------------------------
  getAirlineListByCountry(country) {
    return $axios.$get(`/ma/airline/homepage-list?country=${country}`, {
      useCache: true
    })
  },

  getAirlineList(lang) {
    return $axios.$get(`/ma/airline/list?lang=${lang}&all=1`)
  },

  getAirlineInfo(airlineCode, lang, currency) {
    return $axios.$get(
      `/ma/airline/info?id=${airlineCode}&lang=${lang}&currency=${currency}`
    )
  },

  getAirlineListByRoute(depAirport, arrAirport) {
    return $axios.$get(
      `/ma/airline/list-by-route?depAirport=${depAirport}&arrAirport=${arrAirport}`
    )
  },

  // -------------------------------------------------------------------------
  // AIRPORT
  // -------------------------------------------------------------------------
  getMultiAirportList(lang) {
    return $axios.$get(`/ma/airport/areas?lang=${lang}&all=1`)
  },

  getAirportList(lang) {
    return $axios.$get(`/ma/airport/list?lang=${lang}&all=1`)
  },

  // -------------------------------------------------------------------------
  // APP
  // -------------------------------------------------------------------------

  getAppConfig() {
    return $axios.$get('/ma/app/config')
  },

  // -------------------------------------------------------------------------
  // COUNTRY
  // -------------------------------------------------------------------------

  getCountryList(lang) {
    return $axios.$get(`/ma/country/list?lang=${lang}&all=1`)
  },

  // -------------------------------------------------------------------------
  // CURRENCY
  // -------------------------------------------------------------------------

  getCurrencyList() {
    return $axios.$get('/ma/currency/list')
  },

  // -------------------------------------------------------------------------
  // HOLIDAY
  // -------------------------------------------------------------------------

  getHolidayList(country, lang) {
    return $axios.$get(`/ma/holiday/list?country=${country}&lang=${lang}`)
  },

  // -------------------------------------------------------------------------
  // LANGUAGE
  // -------------------------------------------------------------------------

  getTimezoneList() {
    return $axios.$get('/ma/timezone/list')
  },

  // -------------------------------------------------------------------------
  // Popular Routes
  // -------------------------------------------------------------------------
  getPopularListRoute(airport) {
    return $axios.$get(`/ma/airport/popular-routes?code=${airport}`)
  }
})
