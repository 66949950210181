export default {
  defaultSort: 'lowest',
  defaultSortItems: Object.freeze({
    lowest: 'result.lowestprice',
    highest: 'result.highestprice',
    depart: 'result.departtime',
    arrival: 'result.arrivaltime',
    duration: 'result.duration'
  })
}

export const BOOK_VERIFY_DELAY = 1000 // 1 second
export const LIVECRAWL_MONITOR_DELAY = 1000 // 1 second
export const EXPIRY_TIME = 1500000 // 25 minutes
export const SESSION_WATCHER_INTERVAL = 10000 // 10 seconds

export const flightSelectionType = Object.freeze({
  DEPART: 'depart',
  RETURN: 'return'
})
export const flightSearchType = Object.freeze({
  ONEWAY: 'oneway',
  ROUNDTRIP: 'roundtrip',
  MULTI: 'multicity'
})
export const flightStopsType = Object.freeze({
  ONESTOP: 'onestop',
  TRANSIT: 'transit',
  DIRECT: 'direct'
})
