//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ApzModal from './ApzModal.vue'

export default {
  name: 'ApzModalCard',
  extends: ApzModal,
  props: {
    title: {
      type: [String, Object],
      default: 'Title'
    },
    subtitle: {
      type: [String, Object, Boolean],
      default: false
    }
  },
  computed: {
    titleProps() {
      let props = {
        text: '',
        class: null
      }

      if (typeof this.title === 'string') props.text = this.title
      else if (typeof this.title === 'object')
        props = { ...props, ...this.title }

      return props
    },

    subTitleProps() {
      let props = {
        text: '',
        class: null
      }

      if (typeof this.subtitle === 'string') props.text = this.subtitle
      else if (typeof this.subtitle === 'object')
        props = { ...props, ...this.subtitle }

      return props
    }
  }
}
