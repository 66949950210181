//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    name: {
      type: String,
      default: '404'
    },
    animation: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'medium',
      validator(value) {
        return ['small', 'medium', 'large', 'custom'].includes(value)
      }
    },
    isHorizontal: Boolean,
    isMobile: Boolean
  },
  getIllustrationPath(name) {
    if (name.includes('cdn.airpaz.com')) {
      return name
    }

    if (/.png|.jpg|.jpeg/gi.test(name))
      return require(`@/assets/img/illustration/${name}`)

    const svgName = name.replace('.svg', '')

    return require(`~/assets/svg/illustration/${svgName}.svg`)
  },
  getSizeClass(size) {
    return size !== 'custom' ? `is-${size}` : null
  }
}
