export default $axios => ({
  // -------------------------------------------------------------------------
  // KNOWLEDGE BASE
  // -------------------------------------------------------------------------

  getFaq({ lang, type, sectionSlug, caseSlug }) {
    let path = `knowledge-base/faq?lang=${lang}&type=${type}`

    if (sectionSlug) path += `&sectionSlug=${sectionSlug}`
    if (caseSlug) path += `&caseSlug=${caseSlug}`

    return $axios.$get(path, { progress: false })
  },

  getFaqSearch(params) {
    return $axios.$get(
      'knowledge-base/faq/search',
      { params },
      {
        progress: false
      }
    )
  },

  getSupportPhonesList() {
    return $axios.$get('/support-phones')
  },

  // -------------------------------------------------------------------------
  // MEMBER
  // -------------------------------------------------------------------------
  bindAccount(payload, options) {
    return $axios.$post('/member/bind', payload, {
      ...options,
      progress: false
    })
  },

  unbindAccount(sm, options) {
    return $axios.$delete('/member/unbind?sm=' + sm, {
      ...options,
      progress: false
    })
  },

  getOrderList(payload, options) {
    return $axios.$post('/member/order-list', payload, options)
  },

  login(payload) {
    return $axios.$post('/member/login', payload)
  },

  logout(payload) {
    return $axios.$post('/member/signout', payload)
  },

  signUp(payload) {
    return $axios.$post('/member/signup', payload)
  },

  resetPasswordRequest(payload) {
    return $axios.$post('/member/reset-password-request', payload)
  },

  resetPassword(payload, options = {}) {
    return $axios.$post('/member/reset-password', payload, { ...options })
  },

  resendItinerary(payload) {
    return $axios.$post('/book/resend-itinerary', payload, {
      progress: false
    })
  },

  changePassword(payload) {
    return $axios.$put('/member/change-password', payload)
  },

  orderListFlight(payload) {
    return $axios.$post('/fl/member/order-list', payload, { progress: false })
  },

  getProfile(payload) {
    return $axios.$post('/fl/member/get', payload)
  },

  updateProfile(payload) {
    return $axios.$put('/member/update', payload, { progress: false })
  },

  getContactList() {
    return $axios.$get('/member/contact-list')
  },

  addContactList(payload) {
    return $axios.$post('/member/contact-add', payload)
  },

  updateContactList(payload) {
    return $axios.$put('/member/contact-update', payload)
  },

  deleteContactList(id) {
    return $axios.$delete(`/member/contact-delete?id=${id}`)
  },

  getPassengerList() {
    return $axios.$get('/member/passenger-list')
  },

  addPassengerList(payload) {
    return $axios.$post('/member/passenger-add', payload)
  },

  updatePassengerList(payload) {
    return $axios.$put('/member/passenger-update', payload)
  },

  deletePassengerList(id) {
    return $axios.$delete(`/member/passenger-delete?id=${id}`)
  },

  verifyActivationCode(payload) {
    return $axios.$post('/member/verify-activation-code', payload)
  },

  verifyResetPasswordCode(payload) {
    return $axios.$post('/member/verify-reset-password', payload)
  },

  verifySignUp(payload) {
    return $axios.$post('/member/verify-signup', payload)
  },

  requestVerifyLoginMethod(payload, options = {}) {
    return $axios.$post('/member/request-login-method', payload, { ...options })
  },

  // -------------------------------------------------------------------------
  // BOOK
  // -------------------------------------------------------------------------

  findBook(payload, options = {}) {
    return $axios.$post('/book/find', payload, { progress: false, ...options })
  },

  getBook(payload, options = {}) {
    return $axios.$post('/book/get', payload, { progress: false, ...options })
  },

  getBookStatus(payload, options = {}) {
    return $axios.$post('/book/status', payload, {
      progress: false,
      ...options
    })
  },
  // -------------------------------------------------------------------------
  // AFTER SALES
  // -------------------------------------------------------------------------

  uploadAttachment(payload) {
    return $axios.$post('/upload', payload)
  },

  submitRefundRequest(payload) {
    return $axios.$post('/fl/afs/refund', payload)
  },

  getAfterSalesDetail(payload, options = {}) {
    return $axios.$post('/afs/detail', payload, { progress: false, ...options })
  },

  cancelAftersalesDetail(payload) {
    return $axios.$put('/afs/cancel', payload)
  },

  approveRefund(payload) {
    return $axios.$put('/afs/approve', payload)
  },

  afterSalesFormConfig(payload) {
    return $axios.$post('/fl/afs/form-config', payload)
  },
  addBaggageRequest(payload) {
    return $axios.$post('/fl/afs/add-baggage', payload)
  },
  submitChangePsgData(payload) {
    return $axios.$post('/fl/afs/change-passenger-data', payload)
  },
  submitChangeFlightData(payload) {
    return $axios.$post('/fl/afs/change-flight', payload)
  },
  getAftersalesFee(payload) {
    return $axios.$post('fl/afs/fee', payload)
  },
  // -------------------------------------------------------------------------
  // PAYMENT
  // -------------------------------------------------------------------------
  verifyPromoCode(payload) {
    return $axios.$post('/payment/promo-code', payload)
  },

  cancelPayment(payload) {
    return $axios.$post('/payment/cancel', payload)
  },

  confirmPayment(payload) {
    return $axios.$post('/payment/confirmation', payload)
  },

  paymentBankOptions(payload) {
    return $axios.$post('/payment/bank-options', payload)
  },

  paymentCheckout(payload) {
    return $axios.$post('/payment/checkout', payload, {
      'axios-retry': {
        retries: 3
      }
    })
  },

  submitPayment(payload) {
    return $axios.$post('/payment/submit', payload, {
      progress: false,
      'axios-retry': {
        retries: 0
      },
      timeout: 60000
    })
  },

  getItineraryUrl(payload, options = {}) {
    return $axios.$post('/itinerary/url', payload, {
      progress: false,
      ...options
    })
  },

  getInstallmentOption(payload) {
    return $axios.$post('/payment/installment-options', payload)
  },

  // -------------------------------------------------------------------------
  // NEWSLETTER
  // -------------------------------------------------------------------------
  newsletterSubscribe(payload) {
    return $axios.$post('/newsletter/subscribe', payload, {
      progress: false
    })
  },

  newsletterUnsubscribe(payload) {
    return $axios.$post('/newsletter/unsubscribe', payload, {
      progress: false
    })
  },

  newsletterConfirm(payload) {
    return $axios.$post('/newsletter/confirm', payload, {
      progress: false
    })
  },

  newsletterChangeLang(payload) {
    return $axios.$post('/newsletter/change-language', payload, {
      progress: false
    })
  },

  // -------------------------------------------------------------------------
  // PROMO
  // -------------------------------------------------------------------------
  getOngoingPromo(lang, country) {
    return $axios.$get(`/promo/ongoing?lang=${lang}&country=${country}`)
  },

  getPromoDetail(slug, lang) {
    return $axios.$get(`/promo/detail?slug=${slug}&lang=${lang}`)
  },

  // SUPPORT
  // -------------------------------------------------------------------------
  addTicket(payload) {
    return $axios.$post('/support/add-ticket', payload)
  },

  // -------------------------------------------------------------------------
  // SUPPORT
  // -------------------------------------------------------------------------
  getCarousel(lang, country, platform, type) {
    return $axios.$get(
      `/carousel/get?lang=${lang}&country=${country}&platform=${platform}&type=${type}`,
      {
        progress: false,
        'axios-retry': {
          retries: 3
        }
      }
    )
  },

  // -------------------------------------------------------------------------
  // SEO
  // -------------------------------------------------------------------------
  getSeoConfig() {
    return $axios.$get('/seo-meta', { progress: false })
  },

  // -------------------------------------------------------------------------
  // WALLET
  // -------------------------------------------------------------------------
  getWalletUser(payload) {
    return $axios.$post('/wallet/user', payload)
  },
  walletOrder(payload) {
    return $axios.$post('/wallet/order', payload)
  },
  getTngAccessToken(params) {
    return $axios.$get('/tng-pay', { params })
  },
  // -------------------------------------------------------------------------
  // TOGGLE FEATURES
  // -------------------------------------------------------------------------
  getToggleFeatures(country) {
    return $axios.$get('/forerunner/features', {
      params: { country },
      useCache: true
    })
  },

  // -------------------------------------------------------------------------
  // TRANSLATIONS
  // -------------------------------------------------------------------------
  getTranslations(params) {
    return $axios.$get('/translations', { params })
  },

  // -------------------------------------------------------------------------
  // EMAIL
  // -------------------------------------------------------------------------
  verifyEmail(email, options = {}) {
    return $axios.$post(
      '/email/verify',
      { email },
      { progress: false, ...options }
    )
  },
  // AVAILABLE PAYMENT METHOD
  getPaymentMethodContent(params) {
    return $axios.$get('/contents/available-payments', {
      params
    })
  }
})
