import {
  deburr,
  kebabCase,
  intersection,
  isEmpty,
  pick,
  get,
  flatten,
  cloneDeep,
  omit,
  last,
  compact,
  uniqBy
} from 'lodash'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import referrerMap from '@/config/referrer-map'
import { BOOK_ISSUED } from '@/assets/js/orderStatus'
import { flightStopsType } from '@/config/flight-defaults'

dayjs.extend(customParseFormat)

export const sanitizeAirlineName = name => {
  return deburr(name)
    .replace(
      /\s*([^a-z0-9\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ff\u3131-\uD79D\u0E00-\u0E7F\u0600-\u06FF]+)\s*/gi,
      '-'
    )
    .replace(/^-+|\s|\.|\\|\/|\'/gi, '')
}

export const sanitizeHotelName = name => {
  return deburr(name)
    .replace(/[\u0300-\u036f]|\(|\)|\[|\]|\{|\}|\,/g, '')
    .replace(
      /\s*([^a-z0-9\u4e00-\u9faf\u3040-\u309f\u30a0-\u30ff\u3131-\uD79D\u0E00-\u0E7F\u0600-\u06FF]+)\s*/gi,
      '-'
    )
    .replace(/^-+|\s|\.|\\|\/|\'/gi, '')
}

export const sanitizeAirportName = name => {
  return kebabCase(deburr(name))
}

export const stringifyFlightTicketRoute = (departure, arrival) => {
  let slug = ''
  const { cityName: departCityName = '', code: departCode = '' } =
    departure || {}
  const { cityName: arrivalCityName = '', code: arrivalCode = '' } =
    arrival || {}

  if (!isEmpty(departure))
    slug += departCityName.split(' ').join('-') + '-' + departCode

  if (!isEmpty(arrival)) {
    if (slug) slug += '-'

    slug += arrivalCityName.split(' ').join('-') + '-' + arrivalCode
  }

  return deburr(slug.toLowerCase())
}

export const trimHotelMetaTitle = (
  title = '',
  /* Recommended title length for SEO */
  length = 70
) => {
  const titleLength = !!title ? title.length : 0

  if (titleLength > length) {
    const strToTrim = title.substring(
      title.lastIndexOf('|') + 1,
      title.indexOf('Airpaz') - 1
    )

    return title.replace(strToTrim, '')
  }

  return title
}

export const countTotalRoom = orders => {
  return orders.reduce((n, order) => {
    const countRoom = order.rooms.reduce(
      (m, room) => m + (!!room.countRoom ? room.countRoom : room.roomCount),
      0
    )
    return +n + countRoom
  }, 0)
}

export const buildHotelGuestStr = (guests, context) => {
  const { adult, child = 0, childAges = [] } = guests

  let str = `${adult} ${context.$tc('global.adult', adult)}`

  if (child && child > 0) {
    let childRangeStr = `${Math.min(...childAges)} `

    if (childAges.length > 1) childRangeStr += ` - ${Math.max(...childAges)}`

    str += `, ${child} ${context.$tc(
      'child',
      child
    )} ( ${childRangeStr} ${context.$t('global.yearsold')} )`
  }

  return str
}

export const getHotelImage = ({ photos } = {}) => {
  return !!photos && photos.length > 0
    ? photos[0].url
    : require('~/assets/img/illustration/image_hotel_not_found.png')
}

export const getDurationTime = time => {
  let duration = time

  if (typeof time === 'object') {
    const { start, end } = time
    duration = dayjs(end).diff(dayjs(start), 'second')
  }

  let minutes = Math.floor(Number(duration) / 60)
  const hours = Math.floor(minutes / 60)

  minutes %= 60

  return { hour: hours, minute: minutes }
}

export const getAvailablePaymentMethod = rooms => {
  const arrayOfRooms = rooms.map(room => {
    return room.paymentMethods
  })
  return intersection(...arrayOfRooms)
}

//LZW
export const compressString = c => {
  const x = 'charCodeAt',
    e = {},
    f = c.split(''),
    d = []
  let b,
    a = f[0],
    g = 256
  for (b = 1; b < f.length; b++)
    (c = f[b]),
      null != e[a + c]
        ? (a += c)
        : (d.push(1 < a.length ? e[a] : a[x](0)), (e[a + c] = g), g++, (a = c))
  d.push(1 < a.length ? e[a] : a[x](0))
  for (b = 0; b < d.length; b++) d[b] = String.fromCharCode(d[b])
  return d.join('')
}

export const bufferString = function(string = '') {
  return Buffer.from(string).toString('base64')
}

export const getBufferString = function(string = '') {
  return Buffer.from(string, 'base64').toString()
}

export const validateDate = date => {
  const validateMonth = month => +month >= 1 && +month <= 12
  const validateDay = day => +day >= 1 && +day <= 31

  return (
    dayjs(date, 'YYYY-MM-DD').isValid() &&
    validateMonth(date.split('-')[1]) &&
    validateDay(date.split('-')[2])
  )
}

// -----------------------------------------------------------------------------
// FLIGHT DATE VALIDATOR
// -----------------------------------------------------------------------------
export const validateDepartDate = date => {
  if (validateDate(date) && dayjs(date).diff(dayjs(), 'day') >= 0) return date
  else
    return dayjs()
      .add(1, 'day')
      .format('YYYY-MM-DD')
}

export const validateReturnDate = (returnDate, departDate) => {
  if (
    validateDate(returnDate) &&
    dayjs(returnDate).diff(dayjs(departDate), 'day') >= 0
  )
    return returnDate
  else
    return dayjs(departDate)
      .add(2, 'day')
      .format('YYYY-MM-DD')
}

// -----------------------------------------------------------------------------
// HOTEL DATE VALIDATOR
// -----------------------------------------------------------------------------
export const validateCheckInDate = date => {
  if (
    validateDate(date) &&
    dayjs(date).diff(dayjs(), 'day') >= 0 &&
    dayjs(date).diff(dayjs(), 'day') < 365
  )
    return date
  else
    return dayjs()
      .add(1, 'day')
      .format('YYYY-MM-DD')
}

export const validateCheckOutDate = (co, ci) => {
  if (
    validateDate(co) &&
    dayjs(co).diff(dayjs(ci), 'day') >= 1 &&
    dayjs(co).diff(dayjs(ci), 'day') <= 30
  )
    return co
  else
    return dayjs(ci)
      .add(2, 'day')
      .format('YYYY-MM-DD')
}

export const getFlightCode = airlinesCode => {
  if (typeof airlinesCode !== 'string') return ''

  return airlinesCode.substring(0, 2)
}

export const getFlightKey = flight => {
  const {
    type,
    depAirport,
    arrAirport,
    code,
    traCode,
    depDateTime,
    duration,
    currency
  } = flight

  return `${type}_${depAirport}_${arrAirport}_${code}_${traCode ||
    null}_${dayjs(depDateTime).valueOf()}_${duration}_${currency}`
}

export const getLiveCrawlKey = (query, type = 'depart') => {
  const {
    depDate,
    retDate,
    depAirport,
    arrAirport,
    adult,
    child = 0,
    infant = 0,
    currency,
    supplier
  } = query

  let key = `${type}_${depDate}_${retDate ||
    null}_${depAirport}_${arrAirport}_${adult}_${child}_${infant}_${currency}`

  if (supplier) {
    key += `_${supplier}`
  }

  return key
}

export const getRedirectLink = orderPayload => {
  const isHotel = orderPayload.type.toLowerCase() === 'hotel'
  let link
  if (isHotel) {
    const {
      adult,
      childAges,
      countNight,
      checkInDateTime,
      cityId,
      city
    } = orderPayload.orders[0]
    //Check if the date has passed or not
    const dayDiff = dayjs().diff(checkInDateTime, 'day')
    // if (dayDiff > 0) {
    const ci = dayDiff > 0 ? dayjs().add(1, 'day') : checkInDateTime
    const co =
      dayDiff > 0
        ? dayjs().add(countNight, 'day')
        : dayjs(checkInDateTime).add(countNight, 'day')

    link = {
      path: '/hotel/search',
      query: {
        ci: dayjs(ci).format('YYYY-MM-DD'),
        co: dayjs(co).format('YYYY-MM-DD'),
        ad: adult,
        ca: childAges.join(','),
        ro: countTotalRoom(orderPayload.orders),
        t: 'city',
        id: cityId,
        d: city
      }
    }
    // }
  } else {
    const { depAirport, arrAirport, depDateTime } = orderPayload.orders[0]
    const { passengers } = orderPayload
    const adultType = ['mr', 'ms', 'mrs']
    let adult = 0,
      child = 0,
      infant = 0
    passengers.forEach(psg => {
      if (adultType.includes(psg.title.toLowerCase())) adult += 1
      else if (psg.title.toLowerCase() === 'child') child += 1
      else infant += 1
    })
    const dayDiff = dayjs().diff(depDateTime, 'day')
    link = {
      name: 'flight-search',
      query: {
        adult,
        child,
        infant,
        depAirport,
        arrAirport,
        depDate:
          dayDiff > 0
            ? dayjs()
                .add(1, 'day')
                .format('YYYY-MM-DD')
            : dayjs(depDateTime).format('YYYY-MM-DD')
      }
    }
  }

  return link
}

export const copyToClipboard = async string => {
  if (!navigator.clipboard) {
    const textArea = document.createElement('textarea')
    textArea.value = string
    textArea.style.position = 'fixed'
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()
    document.execCommand('copy')
    document.body.removeChild(textArea)
    return
  }
  return navigator.clipboard.writeText(string)
}

export const getFlightListDetail = (flight, selectionType = 'depFlight') => {
  if (isEmpty(flight)) return null
  const list = []
  if (!!flight.traAirport && flight.type === 'onestop') {
    list.push({
      depAirport: flight.depAirport,
      arrAirport: flight.traAirport,
      airlineCode: flight.code,
      index: 0,
      type: selectionType
    })
    list.push({
      depAirport: flight.traAirport,
      arrAirport: flight.arrAirport,
      airlineCode: flight.traCode,
      index: 1,
      type: selectionType
    })
  } else {
    list.push({
      depAirport: flight.depAirport,
      arrAirport: flight.arrAirport,
      airlineCode: flight.code,
      index: 0,
      type: selectionType
    })
  }
  return list
}

export const isUnset = o => typeof o === 'undefined' || o === null
export const isSet = o => !isUnset(o)

export const hasNonLatin = str => isSet(str) && /[^\u0000-\u007f]/gi.test(str)

export const trim = str => {
  if (typeof str === 'string') return str.replace(/\s+/g, ' ').trim()

  return ''
}

export const removeZeroWidthSpace = str =>
  str.replace(/[\u200B-\u200D\uFEFF]/g, '')

export const combineStrings = (strings, separator = ',') => {
  if (!Array.isArray(strings)) return ''

  return strings.filter(Boolean).join(`${separator} `)
}

/**
 * Handles arithmetic operation on float numbers
 * @param  {...numbers} addends
 */
export const fixRounding = value => Math.round(value * 100) / 100

const _safeArithmetic = (values, fn) =>
  (!isEmpty(values) &&
    values
      .filter(isSet)
      .reduce((acc, value) => fixRounding(fn(Number(acc), Number(value))))) ||
  0

export const add = (...addends) =>
  _safeArithmetic(addends, (acc, value) => acc + value)

export const subtract = (...subtrahends) =>
  _safeArithmetic(subtrahends, (acc, value) => acc - value)

export const multiply = (...multiplicands) =>
  _safeArithmetic(multiplicands, (acc, value) => acc * value)

export const divide = (...dividens) =>
  _safeArithmetic(dividens, (acc, value) => acc / value)

export const isStringEqual = (value, other) => {
  if (isUnset(value) || isUnset(other)) return false
  return `${value}`.toLowerCase() === `${other}`.toLowerCase()
}

export const getPersonType = person => {
  /** @TODO define type by DOB */
  const { title, dob } = person
  let type = title || ''

  if (['mr', 'ms', 'mrs'].some(t => isStringEqual(t, title))) type = 'adult'

  return {
    value: type.toLowerCase(),
    isAdult() {
      return isStringEqual(this.value, 'adult')
    },
    isChild() {
      return isStringEqual(this.value, 'child')
    },
    isInfant() {
      return isStringEqual(this.value, 'infant')
    }
  }
}

export const getOrderFlightStops = (orders, type) => {
  if (!orders || !Array.isArray(orders) || !type) return ''

  const [flight_1, flight_2] = orders.filter(order =>
    isStringEqual(order.type, type)
  )

  const isOneStop = !isEmpty(flight_2)
  const isTransit = !isOneStop && !!flight_1.traCode

  return isOneStop
    ? flightStopsType.ONESTOP
    : isTransit
      ? flightStopsType.TRANSIT
      : flightStopsType.DIRECT
}

/**
 * Converts order data into flight data structure for display purposes
 * [Flight data structure] https://documenter.getpostman.com/view/6294223/RznJmc5L?version=latest#287ecb05-0c8a-40ff-86ba-58ae6bfbd715
 * @param {object} order (response payload from book/get)
 */
export const extractOrderFlightData = order => {
  if (isEmpty(order)) return { searchParams: {}, flights: {} }
  const { passengers = [], orders = [], currency } = order

  const getPsgCountByType = type =>
    passengers.filter(psg => {
      if (typeof type === 'function') return type(psg.title)
      else return isStringEqual(psg.title, type)
    }).length
  const getFreeBag = baggages => {
    baggages = !!baggages ? baggages.filter(n => n != null) : []
    let size = '0',
      unit = 'kg',
      note = {},
      price = 0
    if (!isEmpty(baggages)) {
      ;({ size, unit, note, price } = baggages.find(bag => bag) || {
        unit: 'kg'
      })
      // array 0 without price => freebaggage
      // array 1 always freebaggage
      const sizeBaggage = size ? size.split('+') : ['0']
      size =
        sizeBaggage.length > 1
          ? sizeBaggage[1]
          : sizeBaggage[0] && price == 0
            ? sizeBaggage[0]
            : 0
    }
    return { size: +size, unit, note }
  }
  const getFlightByType = type => {
    /**
     * @TODO handle more than 2 flights
     */
    const [flight_1, flight_2] = orders.filter(order =>
      isStringEqual(order.type, type)
    )

    if (!flight_1) return {}

    const isCombo = get(flight_1, 'rt', false)
    const isOneStop = !isEmpty(flight_2)
    const isTransit = !isOneStop && !!flight_1.traCode
    const psgFares = ['adult', 'infant', 'child'].reduce((fares, type) => {
      const key = type.substring(0, 2) + 'Fare'
      fares[key] = cloneDeep(get(flight_1, `fare.detail.${type}`, {}))
      if (isOneStop && fares[key]) {
        Object.keys(fares[key]).forEach(priceKey => {
          fares[key][priceKey] += get(
            flight_2,
            `fare.detail.${type}.${priceKey}`,
            0
          )
        })
      }
      return fares
    }, {})
    const flight = {
      ...pick(flight_1, [
        'code',
        'traCode',
        'depAirport',
        'arrAirport',
        'traAirport',
        'depTerminal',
        'arrTerminal',
        'traDepTerminal',
        'traArrTerminal',
        'depDateTime',
        'arrDateTime',
        'traDepDateTime',
        'traArrDateTime',
        'orderId',
        'supplierClass',
        'issuedTime',
        'traIssuedTime',
        'refundable',
        'rescheduleable',
        'traRefundable',
        'traRescheduleable',
        'tag',
        'checkInUrl',
        'rt',
        'meal',
        'traMeal',
        'entertainment',
        'traEntertainment'
      ]),
      ...psgFares,
      pnr: [flight_1.pnr],
      ticketNo: [flight_1.ticketNo],
      currency,
      // add disc because flightPriceDetailMixin expect total not to include disc
      total: isCombo ? order.total : add(flight_1.total, flight_1.disc),
      disc: get(flight_1, 'fare.summary.disc', 0),
      baggage: get(flight_1, 'fare.summary.baggage', 0),
      type: flightStopsType.DIRECT,
      baggages: get(flight_1, 'fare.detail.baggage', []).filter(
        bg => !isEmpty(bg)
      ),
      fba: getFreeBag(flight_1.baggage).note,
      freeBag: getFreeBag(flight_1.baggage).size,
      freeCabinBag: get(flight_1, 'freeCabinBag', 0),
      bagUnit: getFreeBag(flight_1.baggage).unit,

      traFba: getFreeBag(flight_1.traBaggage).note,
      traFreeBag: getFreeBag(flight_1.traBaggage).size,
      traFreeCabinBag: get(flight_1, 'traFreeCabinBag', 0),
      traBagUnit: getFreeBag(flight_1.traBaggage).unit
    }

    if (isOneStop) {
      flight.type = flightStopsType.ONESTOP
      flight.traCode = flight_2.code

      flight.arrAirport = flight_2.arrAirport
      flight.traAirport = flight_2.depAirport

      flight.traArrDateTime = flight.arrDateTime
      flight.traDepDateTime = flight_2.depDateTime
      flight.arrDateTime = flight_2.arrDateTime

      flight.traArrTerminal = flight.arrTerminal
      flight.traDepTerminal = flight_2.traDepTerminal
      flight.arrTerminal = flight_2.arrTerminal

      flight.pnr.push(flight_2.pnr)
      flight.ticketNo.push(flight_2.ticketNo)

      flight.baggages = flight.baggages.concat(
        get(flight_2, 'fare.detail.baggage', []).filter(bg => !isEmpty(bg))
      )

      flight.traFreeBag = getFreeBag(flight_2.baggage).size
      flight.traBagUnit = getFreeBag(flight_2.baggage).unit
      flight.total += add(flight_2.total, flight_2.disc)
    } else if (isTransit) {
      flight.type = flightStopsType.TRANSIT
    }

    return flight
  }

  const flights = ['depart', 'return'].reduce((obj, type) => {
    const key = `${type.substring(0, 3)}Flight`
    obj[key] = getFlightByType(type)

    //handle fare and disc in flight combo
    const isCombo = obj[key].rt

    if (isCombo && key === 'retFlight') {
      ;['adult', 'infant', 'child'].map(type => {
        const key = `${type.substring(0, 2)}Fare`

        if (obj.retFlight[key].price) {
          obj.retFlight[key].price += obj.depFlight[key].price
          obj.retFlight[key].tax += obj.depFlight[key].tax
        }
      })
    }

    return obj
  }, {})
  const searchParams = {
    adult: getPsgCountByType(title =>
      ['mr', 'ms', 'mrs'].some(t => isStringEqual(title, t))
    ),
    child: getPsgCountByType('child'),
    infant: getPsgCountByType('infant'),
    currency,
    depAirport: get(flights, 'depFlight.depAirport', ''),
    arrAirport: get(flights, 'depFlight.arrAirport', ''),
    depDate: get(flights, 'depFlight.depDateTime', '').split(' ')[0],
    retDate: get(flights, 'retFlight.depDateTime', '').split(' ')[0]
  }

  const result = {
    searchParams,
    flights,
    total: order.total
  }

  // add other amount
  if (order.payments) {
    const payment = order.payments[order.payments.length - 1] || {}
    result.payment = {
      ...payment,
      isChangeCurrencyPayment: payment.currency !== order.currency,
      changeCurrencyPayment: payment.currency
    }
  }

  if (order.paymentLink) {
    result.paymentLink = order.paymentLink || {}
  }

  return result
}

export const extractOrderHotelData = order => {
  const hotelData = {
    searchParams: {},
    rooms: [],
    total: 0,
    disc: 0,
    currency: '',
    isIssued: false
  }
  if (!isEmpty(order)) {
    const { orders = [], currency } = order
    const orderHotelData = orders[0]

    hotelData.searchParams = {
      checkInDate: orderHotelData.checkInDateTime,
      checkOutDate: orderHotelData.checkOutDateTime,
      nights: orderHotelData.countNight,
      adult: orderHotelData.adult,
      child: orderHotelData.child,
      childAges: orderHotelData.childAges,
      currency
    }
    hotelData.isIssued = order.status.bookStatus === BOOK_ISSUED
    hotelData.currency = order.currency
    hotelData.rooms = flatten(
      orders.reduce((rooms, order) => {
        return rooms.concat(order.rooms)
      }, [])
    )
      /** Normalize basePrice & price to show amount per room */
      .map(room => ({
        ...room,
        basePrice: divide(room.basePrice, room.countRoom),
        price: divide(room.price, room.countRoom)
      }))

    const paymentData = order.payments[order.payments.length - 1]
    if (!!paymentData?.disc) {
      hotelData.disc = paymentData?.disc
    }
    hotelData.total = order.total
  }

  return hotelData
}

export const getRangeFlightDate = (selectedFlight = {}) => {
  const resultRangeDate = {
    from: null,
    to: null
  }
  const defineDateType = {
    depFlight: 'from',
    retFlight: 'to'
  }
  Object.keys(selectedFlight).forEach(flightType => {
    if (!isEmpty(flightType)) {
      resultRangeDate[defineDateType[flightType]] =
        selectedFlight[flightType].depDateTime
    }
  })
  if (!resultRangeDate.to) resultRangeDate.to = resultRangeDate.from
  return resultRangeDate
}

export const generateDefaultFormDates = (
  psgType,
  rangeFlightDate = {},
  ageLimit = {
    adult: { min: 12, max: -1, minWithoutCompanion: 12 },
    child: { min: 2, max: 12 },
    infant: { min: 0, max: 2 }
  }
) => {
  const today = dayjs()
  const dayOfFlight = dayjs(rangeFlightDate.to || rangeFlightDate.from)
  let maxDOB = dayOfFlight.subtract(12, 'year')
  let minDOB = dayOfFlight.subtract(90, 'year')
  let defaultDateDOB = today.subtract(25, 'year')
  let defaultDateExpiry = today.add(2, 'year')

  if (psgType === 'adult' && !isEmpty(ageLimit)) {
    maxDOB = dayOfFlight.subtract(ageLimit.adult.min, 'year')
    if (ageLimit.adult.max !== -1) {
      minDOB = dayOfFlight.subtract(ageLimit.adult.max, 'year')
    }
  }
  if (psgType === 'child') {
    maxDOB = dayOfFlight.subtract(2, 'year')
    minDOB = dayOfFlight.subtract(12, 'year')
    defaultDateDOB = today.subtract(7, 'year')
    if (!isEmpty(ageLimit)) {
      maxDOB = dayOfFlight.subtract(ageLimit.child.min, 'year')
      minDOB = dayOfFlight.subtract(ageLimit.child.max, 'year')
    }
  } else if (psgType === 'infant') {
    maxDOB = dayOfFlight.subtract(2, 'month')
    minDOB = dayOfFlight.subtract(2, 'year')
    defaultDateDOB = today.subtract(1, 'year')
    if (!isEmpty(ageLimit)) {
      maxDOB = dayOfFlight
        .subtract(ageLimit.infant.min, 'year')
        .subtract(2, 'month')
      minDOB = dayOfFlight.subtract(ageLimit.infant.max, 'year')
    }
  }
  // FORMAT ALL THE DATES
  maxDOB = dayjs(maxDOB).format('YYYY-MM-DD')
  minDOB = dayjs(minDOB).format('YYYY-MM-DD')
  defaultDateDOB = dayjs(defaultDateDOB).format('YYYY-MM-DD')
  defaultDateExpiry = dayjs(defaultDateExpiry).format('YYYY-MM-DD')
  const maxExpDate = today.add(20, 'year').format('YYYY-MM-DD')
  const minExpDate = dayOfFlight.add(6, 'month').format('YYYY-MM-DD')
  return {
    minExpDate,
    maxExpDate,
    maxDOB,
    minDOB,
    defaultDateDOB,
    defaultDateExpiry
  }
}

export const filterMultiAirport = list => {
  if (!Array.isArray(list)) return []

  return list.reduce((formattedList, item, index) => {
    if (item.code && item.code.length == 4) {
      const airports = item.airports || []

      formattedList.splice(index, 1)
      formattedList.unshift(...airports)
    }

    return formattedList
  }, list)
}

export const validAirpazCode = code => {
  if (!code || (code && typeof code !== 'string')) return false
  const onlyAlphaNumberic = new RegExp(/^[a-zA-Z0-9]+$/)
  if (!onlyAlphaNumberic.test(code)) return false
  let result = true
  const containAlphabets = new RegExp(/[a-zA-Z]+/)
  const isContainAlphabets = containAlphabets.test(code)
  if (isContainAlphabets && code.length !== 8) {
    result = false
  } else if (!isContainAlphabets && code.length !== 10) {
    result = false
  }
  return result
}
export const safeJSONParse = str => {
  let res = {}
  try {
    res = JSON.parse(str)
  } catch {}
  return res
}
export const sourceIsReferrer = source => {
  return referrerMap.find(ref => ref.id === source)
}
export const defineTrafficSource = (
  adsTraffic = '',
  AffiliateParams = '',
  shouldResponsePar = false
) => {
  let definedSource = ''
  let definedSourcePar = null
  try {
    const adsObj = safeJSONParse(getBufferString(adsTraffic || ''))
    const aftObj = safeJSONParse(getBufferString(AffiliateParams || ''))

    if (adsObj && (adsObj.s || adsObj.a)) {
      definedSource = adsObj.s || adsObj.a
      definedSourcePar = adsTraffic
    }
    if (
      aftObj &&
      aftObj.id &&
      (!definedSource || (definedSource && sourceIsReferrer(definedSource)))
    ) {
      definedSource = aftObj.id
      definedSourcePar = AffiliateParams
    }
  } catch {}
  return !shouldResponsePar ? definedSource : definedSourcePar
}

export const defineTrafficSurl = (adsTraffic = '', AffiliateParams = '') => {
  const surlAdsObj = safeJSONParse(getBufferString(adsTraffic || ''))
  const surlAftObj = safeJSONParse(getBufferString(AffiliateParams || ''))

  if (surlAdsObj && surlAdsObj.surl)
    return {
      surl: surlAdsObj.surl,
      referer: surlAdsObj.referer || 'airpaz.link'
    }
  else if (surlAftObj && surlAftObj.surl) return { surl: surlAftObj.surl }
  else return {}
}

export const makeid = length => {
  let result = ''
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export const getOrderEstimatedIssuedTime = (bookDetail, issuedHour) => {
  if (!issuedHour) {
    issuedHour = Math.max(
      // @ts-ignore
      ...bookDetail.orders.map(ord => ord.issuedTime ?? 0)
    )
  }

  const gmt = dayjs().utcOffset() / 60
  const paymentSuccessOffset = bookDetail.paymentSuccessTime
    ? dayjs().diff(
        dayjs(bookDetail.paymentSuccessTime).add(gmt * 3600, 'second'),
        'hour'
      )
    : issuedHour

  const finalIssuedHour = issuedHour - paymentSuccessOffset

  return finalIssuedHour > 0 ? finalIssuedHour : 0
}

export const stringifyQuery = query => {
  return Object.entries(query)
    .filter(([_key, value]) => typeof value !== 'undefined')
    .map(
      ([key, value]) =>
        encodeURIComponent(key) +
        (value != null ? '=' + encodeURIComponent(value) : '')
    )
    .join('&')
}

export const parseQuery = queryString => {
  const query = {}
  const pairs = queryString.split('&')
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

export const normalizeFlightBookDetail = bookDetail => {
  const { passengers = [], currency } = cloneDeep(bookDetail)
  const isOrderCombo =
    bookDetail.flight.orders.find(ord => ord.type === 'roundtrip') &&
    bookDetail.flight.orders.length === 1
  const getPsgCountByType = type =>
    passengers.filter(psg => {
      if (typeof type === 'function') {
        return type(psg.title)
      } else {
        return isStringEqual(psg.title, type)
      }
    }).length
  const getFreeBag = baggages => {
    const baggageWithFba = baggages.list.find(baggage => {
      return Object.values(baggage).some(bg => bg && bg.fba)
    })

    let fbaValue
    if (baggageWithFba) {
      const bgWithFba = Object.values(baggageWithFba).find(bg => bg && bg.fba)

      if (bgWithFba) {
        fbaValue = bgWithFba.fba
      }

      return fbaValue
    }
  }
  const normalizeFlightOrder = flight => {
    const normalizedFlightObj = {
      ...pick(flight, [
        'type',
        'cabin',
        'stops',
        'duration',
        'tags',
        'segments',
        'baggage',
        'id',
        'ids',
        'status',
        'pnrs'
      ]),
      fares: {
        attributes: [],
        cabinName: '',
        lounge: false,
        priorityBoarding: false
      }
    }

    let flightAttributes = flight.attributes

    if (flight.freeBaggage) {
      flightAttributes = flight.attributes.map((attr, idx) => {
        let freeBaggage

        if (Array.isArray(flight.freeBaggage)) {
          freeBaggage = flight.freeBaggage[idx]
        } else {
          freeBaggage = flight.freeBaggage
        }

        return {
          ...attr,
          ...(freeBaggage && { freeBaggage })
        }
      })
    }

    normalizedFlightObj.fares = {
      attributes: flightAttributes,
      cabinName: flight.cabinName,
      lounge: flight.lounge,
      priorityBoarding: flight.priorityBoarding,
      id: flight.id,
      ids: flight.ids
    }

    return normalizedFlightObj
  }

  const normalizeOrderTravelHack = ordersData => {
    const orderTravelHacks = []
    ordersData.map(orders => {
      orderTravelHacks.push({ ...orders['dptFlight'], id: orders.id })
    })
    const freeBaggage = compact(
      orderTravelHacks.map(ord => getFreeBag(ord.baggage))
    )
    return {
      ...orderTravelHacks[0],
      segments: flatten(
        orderTravelHacks.map(ord =>
          ord.segments.map(segment => ({ ...segment, id: ord.id }))
        )
      ),
      cabinName: orderTravelHacks.map(ord => ord.cabinName),
      duration: add(...orderTravelHacks.map(ord => ord.duration)),
      tags: uniqBy(flatten(orderTravelHacks.map(ord => ord.tags)), 'type'),
      lounge: orderTravelHacks.map(ord => ord.lounge),
      priorityBoarding: orderTravelHacks.map(ord => ord.priorityBoarding),
      status: orderTravelHacks.map(ord => ord.status),
      attributes: flatten(orderTravelHacks.map(ord => ord.attributes)),
      stops: orderTravelHacks.length - 1,
      ids: orderTravelHacks.map(ord => ord.id),
      baggages: orderTravelHacks.map(ord => ord.baggage),
      ...(!isEmpty(freeBaggage) && { freeBaggage }),
      priorityBoarding: orderTravelHacks.map(ord => ord.status),
      type: 'multistops'
    }
  }

  const normalizeOrderSplit = type => {
    const keyType = {
      dptFlight: 'depart',
      rtnFlight: 'return'
    }
    // rtnFlight always null
    const orderType = 'dptFlight'
    const order = bookDetail.flight.orders.filter(
      ord => ord.type === keyType[type]
    )

    if (isEmpty(order)) return null

    if (order.length > 1) {
      // Normalize order contain travel hacks
      //@needcheck
      return normalizeOrderTravelHack(order)
    }

    //@needcheck
    const orderByType = order[0][orderType]
    const freeBaggage = getFreeBag(orderByType?.baggage)

    return {
      ...orderByType,
      ...(!!freeBaggage && { freeBaggage }),
      id: order[0].id,
      baggage: order[0].dptFlight.baggage,
      status: order[0].status
    }
  }

  const getFlightByType = type => {
    let flightOrder

    // Check if order is combo or split
    if (isOrderCombo) {
      const orderByType = bookDetail.flight.orders[0][type]
      const freeBaggage = getFreeBag(orderByType.baggage)

      flightOrder = {
        ...orderByType,
        ...(freeBaggage && { freeBaggage }),
        id: bookDetail.flight.orders[0].id,
        status: bookDetail.flight.orders[0].status
      }
    } else {
      flightOrder = normalizeOrderSplit(type)

      if (!flightOrder) return null
    }

    // Normalize order
    return normalizeFlightOrder(
      omit(flightOrder, [
        'onlineCheckIn',
        'onlineCheckInMsgId',
        'checkInUrl',
        'ticketNumbers'
      ])
    )
  }
  const flights = ['dptFlight', 'rtnFlight'].reduce((obj, key) => {
    const flight = getFlightByType(key)

    if (flight) {
      obj[key] = flight
    }

    return obj
  }, {})

  const searchParams = {
    adult: getPsgCountByType(title =>
      ['mr', 'ms', 'mrs'].some(t => isStringEqual(title, t))
    ),
    child: getPsgCountByType('child'),
    infant: getPsgCountByType('infant'),
    currency,
    depAirport: flights.dptFlight?.segments[0]?.dptAirport.code ?? '',
    arrAirport: last(flights.dptFlight?.segments)?.arrAirport.code ?? '',
    depDate: (flights.dptFlight?.segments[0]?.dptTime ?? '').split(' ')[0],
    ...(Boolean(flights.rtnFlight) && {
      retDate: (flights.rtnFlight?.segments[0]?.dptTime ?? '').split(' ')[0]
    }),
    cabin: flights.dptFlight?.cabinType
  }

  return {
    searchParams,
    flights,
    isCombo: isOrderCombo
  }
}

export default {
  sanitizeHotelName,
  trimHotelMetaTitle,
  countTotalRoom,
  getHotelImage,
  compressString,
  bufferString,
  getBufferString,
  validateDate,
  validateCheckInDate,
  validateCheckOutDate,
  getRedirectLink,
  getAvailablePaymentMethod,
  getFlightCode,
  getDurationTime,
  getFlightKey,
  getLiveCrawlKey,
  getFlightListDetail,
  getOrderEstimatedIssuedTime,
  copyToClipboard,
  fixRounding,
  add,
  subtract,
  multiply,
  divide,
  isStringEqual,
  hasNonLatin,
  trim,
  extractOrderFlightData,
  validAirpazCode,
  removeZeroWidthSpace,
  normalizeFlightBookDetail
}
