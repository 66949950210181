export default $axios => ({
  // -------------------------------------------------------------------------
  // AUTO COMPLETE
  // -------------------------------------------------------------------------

  autocompleteHotel(params) {
    return $axios.$get(
      '/ho/autocomplete/search',
      { params },
      {
        progress: false
      }
    )
  },

  // -------------------------------------------------------------------------
  // BOOK
  // -------------------------------------------------------------------------

  chooseRoom(payload) {
    return $axios.$post('/ho/book/hotel-submit', payload, {
      progress: false
    })
  },

  cancelRoom(payload) {
    return $axios.$post('/ho/book/cancel-room', payload)
  },

  checkCancellationStatus(payload) {
    return $axios.$post('/ho/book/cancel-status', payload)
  },

  hotelFormConfig(payload) {
    return $axios.$post('/ho/book/form-config', payload)
  },

  cancelOrder(payload) {
    return $axios.$post('/ho/book/cancel-order', payload)
  },

  hotelOrder(payload, options = {}) {
    return $axios.$post('/ho/book/order', payload, { ...options })
  },

  hotelCrossSelling(payload, options = {}) {
    return $axios.$post('/ho/hotel/recommendation-from-flight', payload, {
      ...options
    })
  },

  // -------------------------------------------------------------------------
  // PROMO
  // -------------------------------------------------------------------------

  getPromoHomepageHotel(country, currency, lang) {
    return $axios.$get(
      `/ho/promo/homepage-list?country=${country}&currency=${currency}&lang=${lang}`,
      { useCache: true }
    )
  },

  // -------------------------------------------------------------------------
  // HOTEL
  // -------------------------------------------------------------------------

  getHotelDetail(payload) {
    return $axios.$get(
      '/ho/hotel/detail',
      { params: payload },
      { progress: false }
    )
  },

  getHotelSearch(payload, cancelToken) {
    return $axios.$post('/ho/hotel/search', payload, cancelToken)
  },

  getRoomSearchId(payload, options = {}) {
    return $axios.$post('/ho/hotel/livecrawl-room', payload, {
      progress: false,
      ...options
    })
  },

  getRooms(payload, options = {}) {
    return $axios.$post('/ho/hotel/livecrawl-detail', payload, {
      progress: false,
      ...options
    })
  },

  getHotelNearby(payload) {
    return $axios.$get('/ho/hotel/nearest', { params: payload })
  },

  // -------------------------------------------------------------------------
  // REVIEW
  // -------------------------------------------------------------------------
  submitReview(payload) {
    return $axios.$post('/ho/review/submit', payload, { progress: false })
  },

  getReviewDetail(payload) {
    return $axios.$post('/ho/review/detail', payload, { progress: false })
  },

  getTripadvisorRating(payload) {
    return $axios.$get(
      '/ho/review/tripadvisor',
      { params: payload },
      { progress: false }
    )
  },

  // -------------------------------------------------------------------------
  // OTHERS
  // -------------------------------------------------------------------------
  getSearchNameById(payload) {
    return $axios.$get('/ho/hotel/search-name', {
      params: payload,
      progress: false
    })
  }
})
