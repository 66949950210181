//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ApzModal',
  props: {
    modalId: {
      type: String,
      default: ''
    },
    openWithHash: {
      type: Boolean,
      default: true
    },
    active: Boolean,
    component: {
      type: [Object, Function],
      default: () => {}
    },
    content: {
      type: String,
      default: ''
    },
    customModalClass: {
      type: String,
      default: ''
    },
    programmatic: Boolean,
    backdrop: {
      type: Boolean,
      default: true
    },
    props: {
      type: Object,
      default: () => ({})
    },
    events: {
      type: Object,
      default: () => ({})
    },
    width: {
      type: [String, Number],
      default: 960
    },
    hasClose: {
      type: Boolean,
      default: false
    },
    hasModalCard: Boolean,
    animation: {
      type: String,
      default: ''
    },
    isBottom: Boolean,
    isMobile: Boolean,
    canCancel: {
      type: [Array, Boolean],
      default: () => ['escape', 'x', 'outside', 'button']
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    scroll: {
      type: String,
      default: 'clip',
      validator: value => {
        return ['clip', 'keep'].indexOf(value) >= 0
      }
    }
  },
  data() {
    return {
      isActive: this.active || false,
      savedScrollTop: null,
      newWidth: typeof this.width === 'number' ? this.width + 'px' : this.width
    }
  },
  computed: {
    newAnimation() {
      return this.isMobile && (!this.animation || this.isBottom)
        ? 'modal-bottom'
        : this.animation || 'slide'
    },

    modalClasses() {
      return [
        {
          // 'modal-content': !this.hasModalCard,
          'no-backdrop': !this.backdrop,
          'is-bottom': this.isBottom
        },
        this.customModalClass
      ]
    },

    cancelOptions() {
      return typeof this.canCancel === 'boolean'
        ? this.canCancel
          ? ['escape', 'x', 'outside', 'button']
          : []
        : this.canCancel
    },

    showX() {
      return this.cancelOptions.indexOf('x') >= 0
    }
  },
  watch: {
    active(value) {
      if (value) {
        this.$emit('open')

        if (!!this.modalId) history.pushState({ modalId: this.modalId }, '')
      } else {
        if (!this.backdrop) this.$emit('close')

        if (!!this.modalId && history.state.modalId === this.modalId)
          history.back()
      }
      this.isActive = value
    },

    isActive() {
      this.handleScroll()
    },

    $route: {
      handler({ hash, name }, { name: oldName } = {}) {
        if (!this.openWithHash) return

        if (this.active && !!oldName && name !== oldName)
          return this.$emit('update:active', false)

        const hashName = hash?.replace('#', '')

        if (!process.client || !this.modalId) return

        if (!!hashName && hashName === this.modalId) {
          this.$emit('update:active', true)
        } else this.$emit('update:active', false)
      },
      immediate: true
    }
  },
  created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('keyup', this.keyPress)
    }
  },
  beforeMount() {
    if (this.programmatic) {
      if (!!this.modalId) history.pushState({ modalId: this.modalId }, '')

      document.body.appendChild(this.$el)
    }
  },
  mounted() {
    if (this.programmatic) this.isActive = true
    else if (this.isActive) this.handleScroll()

    !!window && window.addEventListener('popstate', this.close)
  },
  beforeDestroy() {
    if (typeof window === 'undefined') return

    window.removeEventListener('popstate', this.close)
    document.removeEventListener('keyup', this.keyPress)

    document.documentElement.classList.remove('is-clipped')
    const savedScrollTop = !this.savedScrollTop
      ? document.documentElement.scrollTop
      : this.savedScrollTop
    document.body.classList.remove('is-noscroll')
    document.documentElement.scrollTop = savedScrollTop
    document.body.style.top = null
  },
  methods: {
    handleScroll() {
      if (typeof window === 'undefined') return

      if (this.scroll === 'clip' && this.backdrop) {
        if (this.isActive) {
          document.documentElement.classList.add('is-clipped')
        } else {
          document.documentElement.classList.remove('is-clipped')
        }

        if (!this.isSafariIOS) return
      }

      this.savedScrollTop = !this.savedScrollTop
        ? document.documentElement.scrollTop
        : this.savedScrollTop

      if (this.isActive) {
        document.body.classList.add('is-noscroll')
      } else {
        document.body.classList.remove('is-noscroll')
      }

      if (this.isActive) {
        document.body.style.top = `-${this.savedScrollTop}px`
        return
      }
      document.documentElement.scrollTop = this.savedScrollTop
      document.body.style.top = null
      this.savedScrollTop = null
    },

    /**
     * Close the Modal if canCancel and call the onCancel prop (function).
     */
    cancel(method) {
      if (this.cancelOptions.indexOf(method) < 0) return

      this.onCancel.apply(null, arguments)
      this.close()
    },

    /**
     * Call the onCancel prop (function).
     * Emit events, and destroy modal if it's programmatic.
     */
    close() {
      this.$emit('close')
      this.$emit('update:active', false)

      // Timeout for the animation complete before destroying
      if (this.programmatic) {
        this.isActive = false
        setTimeout(() => {
          if (!!this.modalId && history.state.modalId === this.modalId)
            history.back()

          this.$destroy()

          if (typeof this.$el.remove !== 'undefined') this.$el.remove()
          else if (typeof this.$el.parentNode !== 'undefined')
            this.$el.parentNode.removeChild(this.$el)
        }, 150)
      }
    },

    /**
     * Keypress event that is bound to the document.
     */
    keyPress(event) {
      // Esc key
      if (this.isActive && event.keyCode === 27) this.cancel('escape')
    }
  }
}
