import { lowerCase, get } from 'lodash'

export const BOOK_COMPLETED = 'completed'
export const BOOK_CONFIRMED = 'confirmed'
export const BOOK_NEEDPAYMENT = 'needpayment'
export const BOOK_PROCESSING = 'processing'
export const BOOK_PAYMENT_IN_PROCESS = 'paymentinprocess'
export const BOOK_PAYSUCCESS = 'paymentsuccess'
export const BOOK_PAYCONFIRM = 'paymentconfirm'
export const BOOK_PAYMENT_DONE = 'done'
export const BOOK_DONE = 'bookdone'
export const BOOK_ISSUED = 'issued'
export const BOOK_EXPIRED = 'expired'
export const BOOK_FAILED = 'bookfailed'
export const BOOK_QUEUEING = 'queueing'
export const BOOK_WAITING = 'waiting'
export const BOOK_CANCELLED = 'cancelled'
export const BOOK_ERROR = 'bookerror'
export const BOOK_PRICE_CHANGED = 'pricechanged'
export const BOOK_WAITING_CHANGED = 'waitingchanged'

export const BookStatusGroups = {
  EXPIRED: [BOOK_ERROR, BOOK_CANCELLED, BOOK_EXPIRED, BOOK_FAILED],
  NEED_PAYMENT: [BOOK_NEEDPAYMENT, BOOK_PROCESSING],
  PAYABLE: [BOOK_DONE, BOOK_WAITING],
  BOOKABLE: [BOOK_DONE, BOOK_WAITING, BOOK_PRICE_CHANGED, BOOK_WAITING_CHANGED],
  PROCESSING: [BOOK_PROCESSING, BOOK_WAITING],
  PRICE_CHANGED: [BOOK_PRICE_CHANGED, BOOK_WAITING_CHANGED]
}

export default class OrderStatus {
  constructor(data, customizer) {
    this._status = lowerCase(
      customizer
        ? customizer(data)
        : typeof data === 'string'
          ? data
          : get(data, 'status.bookStatus', '')
    )
  }

  isIssued() {
    return this._status === BOOK_ISSUED
  }

  isCancelled() {
    return this._status === BOOK_CANCELLED
  }

  isNeedPayment() {
    return this._status === BOOK_NEEDPAYMENT || this._status === BOOK_PROCESSING
  }

  isPaymentInProcess() {
    return this._status === BOOK_PAYMENT_IN_PROCESS
  }

  isPaymentSuccess() {
    return this._status === BOOK_PAYSUCCESS || this._status === BOOK_PAYCONFIRM
  }

  isNeedRebook() {
    return BookStatusGroups.EXPIRED.includes(this._status)
  }

  isExpired() {
    return BookStatusGroups.EXPIRED.includes(this._status)
  }

  get value() {
    return statusMap[this._status]
      ? statusMap[this._status].value
      : statusMap.default.value
  }

  get color() {
    return statusMap[this._status]
      ? statusMap[this._status].color
      : statusMap.default.color
  }
}

const statusMap = Object.freeze({
  completed: {
    value: 'completed',
    color: 'is-success'
  },
  confirmed: {
    value: 'confirmed',
    color: 'is-success'
  },
  processing: {
    value: 'rt_Processing',
    color: 'is-warning'
  },
  needpayment: {
    value: 'needpayment',
    color: 'is-warning'
  },
  paymentsuccess: {
    value: 'paymentsuccess',
    color: 'is-success'
  },
  paymentinprocess: {
    value: 'paymentinprocess',
    color: 'is-warning'
  },
  paymentconfirm: {
    value: 'paymentsuccess',
    color: 'is-success'
  },
  done: {
    value: 'done',
    color: 'is-success'
  },
  issued: {
    value: 'issued',
    color: 'is-success'
  },
  expired: {
    value: 'expired',
    color: 'is-danger'
  },
  bookexpired: {
    value: 'expired',
    color: 'is-danger'
  },
  bookfailed: {
    value: 'failed',
    color: 'is-danger'
  },
  failed: {
    value: 'failed',
    color: 'is-danger'
  },
  queueing: {
    value: 'queueing',
    color: ''
  },
  waiting: {
    value: 'needpayment',
    color: 'is-warning'
  },
  cancelled: {
    value: 'cancelled',
    color: 'is-danger'
  },
  default: {
    value: '',
    color: ''
  }
})
