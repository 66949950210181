export default [
  {
    url: '/en/promo',
    items: [
      [
        {
          property: 'og:site_name',
          content: 'Airpaz'
        }
      ]
    ]
  },
  {
    url: '/en/airlines/BX-Air-Busan',
    items: [
      [
        {
          title: 'Air Busan is the best airline ever exist in this whole world'
        },
        {
          name: 'description',
          content:
            'This is the complete description about Air Busan, THE best airline in the world'
        }
      ]
    ]
  },
  {
    url: '/zh-tw',
    items: [
      [
        {
          title: '廉價航空，國際機票，特價機票查詢預訂網站'
        }
      ]
    ]
  },
  {
    url: '/id/airlines/QF-Qantas-Airways',
    items: [
      [
        {
          title:
            'Dapatkan Harga Tiket Penerbangan Qantas(QF) untuk Domestic dan Internasional'
        },
        {
          name: 'description',
          content:
            'Jelajahi Dunia Bersama Qantas(QF), Beli Tiket Pesawat Qantas Airways di Airpaz, Temukan Berbagai Rute Favorit Diantaranya Jakarta-Australia, Sydney, Melbourne.'
        }
      ]
    ]
  },
  {
    url: '/en/airlines/TG-Thai-Airways',
    items: [
      [
        {
          title:
            'Book Thai Airways Online on Airpaz | Fly to 35 Countries Domestic & International'
        },
        {
          name: 'description',
          content:
            'Thai Airways Thailand On Airpaz - Book Flight to Thailand, Phuket, Singapore'
        }
      ]
    ]
  },
  {
    url: '/en/flight/to/2',
    items: []
  },
  {
    url: '/en/airlines/AK-AirAsia',
    items: [
      [
        {
          title:
            'Book Airasia Malaysia AK Online - Find AirAsia Cheap Flight Tickets on Airpaz'
        },
        {
          name: 'description',
          content:
            'AirAsia, Largest Low-Cost Airline in Southeast Asia, Find the Best Promotion Price on Airpaz'
        }
      ]
    ]
  }
]
