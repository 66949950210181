//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  inheritAttrs: false,

  props: {
    icon: {
      type: [Array, String],
      required: true
    },
    fixedWidth: {
      type: Boolean,
      default: false
    },
    rotation: {
      type: [String, Number],
      default: null,
      validator: value => [90, 180, 270].indexOf(parseInt(value, 10)) > -1
    },
    size: {
      type: String,
      default: null,
      validator: value =>
        [
          'lg',
          'xs',
          'sm',
          '1x',
          '2x',
          '3x',
          '4x',
          '5x',
          '6x',
          '7x',
          '8x',
          '9x',
          '10x'
        ].indexOf(value) > -1
    }
  },

  computed: {
    iconWrapperClasses() {
      const classes = []

      if (this.size) {
        classes.push('icon-' + this.size)
      }

      if (this.fixedWidth) {
        classes.push('icon-fw')
      }

      return classes
    },

    iconClasses() {
      let classes = []

      if (Array.isArray(this.icon)) {
        classes = classes.concat([this.icon[0], 'fa-' + this.icon[1]])
      } else {
        classes = classes.concat(['fal', 'fa-' + this.icon])
      }

      if (this.size) {
        classes.push('fa-' + this.size)
      }

      if (this.fixedWidth) {
        classes.push('fa-fw')
      }

      if (this.rotation) {
        classes.push(`fa-rotate-${this.rotation}`)
      }

      return classes
    }
  }
}
