export default [
  {
    id: 'FB',
    keyword: 'l.facebook.com'
  },
  {
    id: 'GOOG',
    keyword: 'google'
  },
  {
    id: 'GURL',
    keyword: 'goo.gl'
  },
  {
    id: 'BING',
    keyword: 'bing'
  },
  {
    id: 'NAVER',
    keyword: 'naver'
  },
  {
    id: 'YHOOJP',
    keyword: 'yahoo.co.jp'
  },
  {
    id: 'YHOO',
    keyword: 'yahoo'
  },
  {
    id: 'BAIDU',
    keyword: 'baidu'
  },
  {
    id: 'HOOT',
    keyword: 'ow.ly'
  },
  {
    id: 'MAIL',
    keyword: 'oemarket'
  },
  {
    id: 'YOUTUBE',
    keyword: 'youtube.com'
  },
  {
    id: 'BLOG',
    keyword: 'blog.airpaz'
  },
  {
    id: 'MAIL',
    keyword: 'connect.airpaz'
  },
  {
    id: 'WIDGET',
    keyword: '/widget/search'
  }
]
