import flightServices from './flight'
import hotelServices from './hotel'
import generalServices from './general'
import masterServices from './master'
import agentServices from './agent'

export default $axios => {
  const flight = flightServices($axios)
  const hotel = hotelServices($axios)
  const general = generalServices($axios)
  const master = masterServices($axios)
  const agent = agentServices($axios)

  return {
    getRefreshToken(payload) {
      return $axios.$post('/oauth/token', payload)
    },

    getCSRF() {
      return $axios.$get('/csrf')
    },

    // -------------------------------------------------------------------------
    // AIRLINE PALLETE
    // -------------------------------------------------------------------------
    getColorPalette(url) {
      return $axios.$post('/util/palette', url)
    },

    ...flight,
    ...hotel,
    ...general,
    ...master,
    ...agent
  }
}
