// -------------------------------------------------------------------------
// AUTH
// -------------------------------------------------------------------------
export const ACCOUNT_DISABLED = 'ACCOUNT_DISABLED'
export const AUTH_FAILED = 'AUTH_FAILED'
export const UNREGISTERED_MEMBER = 'UNREGISTERED_MEMBER'
export const INVALID_SM_TOKEN = 'INVALID_SM_TOKEN'
export const UNAUTHORIZED = 'UNAUTHORIZED'
export const ALREADY_UNSUBSCRIBED = 'ALREADY_UNSUBSCRIBED'

// -------------------------------------------------------------------------
// PAYMENT
// -------------------------------------------------------------------------
export const ERR_INSUFFICIENT_DEPOSIT_BALANCE =
  'ERR_INSUFFICIENT_DEPOSIT_BALANCE'
export const PAYMENT_IS_PROCESSING = 'PAYMENT_IS_PROCESSING'
export const INVALID_PAYMENT_METHOD = 'INVALID_PAYMENT_METHOD'
export const PAYMENT_CANCELLED = 'PAYMENT_CANCELLED'
export const ERR_INVALID_PAYMETHOD = 'ERR_INVALID_PAYMETHOD'
export const ERR_INVALID_BANK = 'ERR_INVALID_BANK'
export const ERR_PAYMENT_EMPTY = 'ERR_PAYMENT_EMPTY'
export const ERR_PAYMENT_DONE = 'ERR_PAYMENT_DONE'
export const ERR_RATE_FAILED = 'ERR_RATE_FAILED'
export const CLOSED_OUT_OF_HOURS = 'CLOSED_OUT_OF_HOURS'
export const XPAY_ERROR = 'XPAY_ERROR'
export const BOOKING_IN_PROGRESS = 'BOOKING_IN_PROGRESS'
export const ONE_OF_BOOK_FAILED = 'ONE_OF_BOOK_FAILED'
export const PRICE_CHANGED = 'PRICE_CHANGED'
export const BOOK_FAILED = 'BOOK_FAILED'

// -------------------------------------------------------------------------
// GLOBAL
// -------------------------------------------------------------------------
export const ERROR_INTERNAL = 'ERROR_INTERNAL'
export const ERR_CODE_USED = 'ERR_CODE_USED'
export const NOT_FOUND = 'NOT_FOUND'
export const ERR_DATA_EXPIRED = 'ERR_DATA_EXPIRED'
export const ERR_CODE_EXPIRED = 'ERR_CODE_EXPIRED'

// -------------------------------------------------------------------------
// ORDER
// -------------------------------------------------------------------------
export const INVALID_BOOK_TYPE = 'INVALID_BOOK_TYPE'
export const ERR_INVALID_AIRPORT = 'ERR_INVALID_AIRPORT'
export const PRICE_ALERT_NOT_FOUND = 'PRICE_ALERT_NOT_FOUND'
export const ALREADY_CANCELLED = 'ALREADY_CANCELLED'
export const TRX_NOT_FOUND = 'TRX_NOT_FOUND'
export const TRX_MEMBER_NOT_FOUND = 'TRX_MEMBER_NOT_FOUND'
export const ERR_EMAIL_ALREADY_REGISTERED = 'ERR_EMAIL_ALREADY_REGISTERED'
export const ERR_INVALID_ORDER_CURRENCY = 'ERR_INVALID_ORDER_CURRENCY'
export const ERR_UNMATCHED_EMAIL = 'ERR_UNMATCHED_EMAIL'
export const PDF_NOT_FOUND = 'PDF_NOT_FOUND'
export const INVALID_HASH = 'INVALID_HASH'

// -------------------------------------------------------------------------
// VCH PROMO
// -------------------------------------------------------------------------
export const INVALID_PROMO_CURRENCY = 'INVALID_PROMO_CURRENCY'
export const EXPIRED_VCH = 'EXPIRED_VCH'
export const INVALID_VCH_OWNER = 'INVALID_VCH_OWNER'
export const NOT_FOUND_VOUCHER = 'NOT_FOUND_VOUCHER'
export const ERR_PROMO_PROHIBITED = 'ERR_PROMO_PROHIBITED'
export const INVALID_COUNTRY = 'INVALID_COUNTRY'
export const ERR_INVALID_PROMO_PERIOD = 'ERR_INVALID_PROMO_PERIOD'
export const INVALID_TRAFFIC_SOURCE = 'INVALID_TRAFFIC_SOURCE'
export const INVALID_AD_CAMPAIGN = 'INVALID_AD_CAMPAIGN'
export const INVALID_PROMO_PAYMENT_METHOD = 'INVALID_PROMO_PAYMENT_METHOD'
export const ERR_FIRST_PURCHASE_ONLY = 'ERR_FIRST_PURCHASE_ONLY'
export const ERR_PROMO_ONLY_MEMBER = 'ERR_PROMO_ONLY_MEMBER'
export const VCH_INVALID_FOR_MEMBER = 'VCH_INVALID_FOR_MEMBER'
export const INVALID_ALLOWED_PAX = 'INVALID_ALLOWED_PAX'
export const INVALID_FLIGHT_TYPE = 'INVALID_FLIGHT_TYPE'
export const INVALID_ROUTE = 'INVALID_ROUTE'
export const INVALID_AIRLINES_CODE = 'INVALID_AIRLINES_CODE'
export const INVALID_FLIGHT_CODE = 'INVALID_FLIGHT_CODE'
export const SSR_REQUIRED = 'SSR_REQUIRED'
export const INVALID_FLIGHT_DIRECTION = 'INVALID_FLIGHT_DIRECTION'
export const INVALID_ALLOWED_NIGHT = 'INVALID_ALLOWED_NIGHT'
export const INVALID_HOTEL = 'INVALID_HOTEL'
export const INVALID_HOTEL_STAR = 'INVALID_HOTEL_STAR'
export const INVALID_PAYMENT_SCHEDULE = 'INVALID_PAYMENT_SCHEDULE'
export const INVALID_HOTEL_TYPE = 'INVALID_HOTEL_TYPE'
export const INVALID_HOTEL_REGION = 'INVALID_HOTEL_REGION'
export const INVALID_HOTEL_DISTRICT = 'INVALID_HOTEL_DISTRICT'
export const INVALID_HOTEL_CITY = 'INVALID_HOTEL_CITY'
export const INVALID_ROOM_TYPE = 'INVALID_ROOM_TYPE'
export const ERR_INVALID_TRAVEL_PERIOD = 'ERR_INVALID_TRAVEL_PERIOD'
export const ERR_INSUFFICIENT_PURCHASE = 'ERR_INSUFFICIENT_PURCHASE'
export const EXCEEDING_PURCHASE_LIMIT = 'EXCEEDING_PURCHASE_LIMIT'
export const ERR_INVALID_ORDER_PLATFORM = 'ERR_INVALID_ORDER_PLATFORM'
export const ERR_INVALID_ORDER_WEEKDAY = 'ERR_INVALID_ORDER_WEEKDAY'
export const INVALID_DAY_OF_MONTH = 'INVALID_DAY_OF_MONTH'
export const CHILD_NOT_EXISTS = 'CHILD_NOT_EXISTS'
export const ERR_INVALID_GENDER = 'ERR_INVALID_GENDER'
export const NO_QUOTA_AVAILABLE = 'NO_QUOTA_AVAILABLE'
export const VCH_ALREADY_USED = 'VCH_ALREADY_USED'
export const PROMO_NOT_FOR_AFS = 'PROMO_NOT_FOR_AFS'

export default {
  [ERR_EMAIL_ALREADY_REGISTERED]: {
    title: 'signup.emailexists',
    message: ''
  },
  [ACCOUNT_DISABLED]: {
    title: 'account.accountdisabledtitle',
    message: 'account.accountdisableddesc'
  },
  [AUTH_FAILED]: {
    title: 'global.loginfailed',
    message: 'global.wrongemailorpassword'
  },
  [UNREGISTERED_MEMBER]: {
    title: 'global.loginfailed',
    message: 'global.wrongemailorpassword'
  },
  [ERR_PROMO_PROHIBITED]: {
    title: '',
    message: 'payment.invalidmethod'
  },
  [ERR_INVALID_PAYMETHOD]: {
    title: '',
    message: 'payment.wrongmethod'
  },
  [ERR_INVALID_BANK]: {
    title: '',
    message: 'deposit.err_INVALID_BANK'
  },
  [ERR_INSUFFICIENT_DEPOSIT_BALANCE]: {
    title: '',
    message: 'payment.balancenotenough'
  },
  [PAYMENT_IS_PROCESSING]: {
    title: '',
    message: 'payment.paymentstillprocessing'
  },
  [INVALID_PAYMENT_METHOD]: {
    title: '',
    message: 'payment.wrongmethod'
  },
  [INVALID_PROMO_CURRENCY]: {
    title: '',
    message: 'promo.invalidpromocurrency'
  },
  [PAYMENT_CANCELLED]: {
    title: '',
    message: 'payment.paymentcancelled'
  },
  [CLOSED_OUT_OF_HOURS]: {
    title: '',
    message: 'payment.bankactivehours'
  },
  [INVALID_VCH_OWNER]: {
    title: 'promo.voucherownertitle',
    message: 'promo.voucherownerdesc'
  },
  [EXPIRED_VCH]: {
    title: 'promo.voucherexpiredtitle',
    message: 'promo.voucherexpireddesc'
  },
  [NOT_FOUND_VOUCHER]: {
    title: 'promo.novouchertitle',
    message: 'promo.novoucherdesc'
  },
  [ERR_INVALID_ORDER_CURRENCY]: {
    title: 'promo.invalidpromotitle',
    message: 'promo.invalidpromocurrency'
  },
  [INVALID_BOOK_TYPE]: {
    title: '',
    message: ''
  },
  [XPAY_ERROR]: {
    title: '',
    message: 'payment.paymentundermaintenance'
  },
  [PROMO_NOT_FOR_AFS]: {
    title: 'after_sales_general.promovouchernotforafstitle',
    message: 'after_sales_general.promovouchernotforafsdesc'
  }
}
