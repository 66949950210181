//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Illustration from '@/components/svg/Illustration'

export default {
  name: 'ApzDialog',
  components: {
    Illustration
  },
  props: {
    active: Boolean,
    customModalClass: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    illustration: {
      type: String,
      default: ''
    },
    messageStyle: {
      type: String,
      default: ''
    },
    confirmText: {
      type: String,
      default: 'Confirm'
    },
    cancelText: {
      type: String,
      default: ''
    },
    horizontalButtons: Boolean,
    reverseButtons: Boolean,
    type: {
      type: String,
      default: 'is-primary'
    },
    backdrop: {
      type: Boolean,
      default: true
    },
    width: {
      type: [String, Number],
      default: 320
    },
    animation: {
      type: String,
      default: 'zoom-out'
    },
    canCancel: {
      type: [Array, Boolean],
      default: () => ['escape', 'x', 'outside', 'button']
    },
    onConfirm: {
      type: Function,
      default: () => {}
    },
    onCancel: {
      type: Function,
      default: () => {}
    },
    actions: {
      type: Array,
      default: () => []
    },
    scroll: {
      type: String,
      default: null,
      validator: value => {
        return ['clip', 'keep'].indexOf(value) >= 0
      }
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      savedScrollTop: null,
      newWidth: typeof this.width === 'number' ? this.width + 'px' : this.width
    }
  },
  computed: {
    modalClasses() {
      return [
        {
          'no-backdrop': !this.backdrop
        },
        this.customModalClass
      ]
    },

    cancelOptions() {
      return typeof this.canCancel === 'boolean'
        ? this.canCancel
          ? ['escape', 'x', 'outside', 'button']
          : []
        : this.canCancel
    },

    showX() {
      return this.cancelOptions.indexOf('x') >= 0
    },

    dialogActions() {
      return this.actions.map(action => {
        const newActions = { ...action }

        newActions.onClick = () => {
          action.onClick?.()
          action.variant === 'cancel' ? this.cancel('button') : this.close()
        }

        newActions.type =
          action.variant === 'cancel' ? 'is-primary-light' : 'is-primary'

        return newActions
      })
    }
  },
  watch: {
    isActive(value) {
      if (value) {
        this.$emit('open')

        this.clipView(true)
      } else {
        if (!this.backdrop) this.$emit('close')

        this.clipView(false)
      }

      this.isActive = value
    }
  },
  beforeMount() {
    this.$nextTick(() => {
      document.body.appendChild(this.$el)
    })
  },
  created() {
    if (typeof window !== 'undefined') {
      document.addEventListener('keyup', this.keyPress)
    }
  },
  mounted() {
    this.isActive = true
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.keyPress)
  },
  destroyed() {
    this.clipView(false)
  },
  methods: {
    clipView(clip) {
      if (clip) {
        document.documentElement.classList.add('is-clipped')
      } else {
        const isOtherModalOpened =
          document.querySelectorAll('.modal.is-active').length > 1

        if (!isOtherModalOpened) {
          document.documentElement.classList.remove('is-clipped')
        }
      }
    },
    /**
     * Close the Modal if canCancel and call the onCancel prop (function).
     */
    cancel(method) {
      if (this.cancelOptions.indexOf(method) < 0) return
      if (method !== 'outside') this.onCancel.apply(null, arguments)
      this.close()
    },

    confirm() {
      this.onConfirm()
      this.close()
    },

    /**
     * Call the onCancel prop (function).
     * Emit events, and destroy modal if it's programmatic.
     */
    close() {
      this.isActive = false
      // Timeout for the animation complete before destroying
      setTimeout(() => {
        this.$destroy()

        if (typeof this.$el.remove !== 'undefined') this.$el.remove()
        else if (typeof this.$el.parentNode !== 'undefined')
          this.$el.parentNode.removeChild(this.$el)
      }, 150)
    },

    /**
     * Keypress event that is bound to the document.
     */
    keyPress(event) {
      // Esc key
      if (this.isActive && event.keyCode === 27) this.cancel('escape')
    }
  }
}
